import React from 'react';
import '../App.css';
import { AddOrganizations, baseApiUrl } from '../api/api';
import axios from 'axios';
import { store } from '../App';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

const org_options = [
    { value: '', label: 'Select', forms: [] },
    { value: '1', label: 'Provider', forms: [{name:'Client', type: 1}] },
    { value: '2', label: 'County', forms: [{name:'Client', type: 1}] },
    { value: '3', label: 'Foster Care', forms: [{name:'Client', type: 1}, {name:'Family', type: 2}] },
    { value: '4', label: 'Detention', forms: [{name:'Client', type: 1}] }
];

const menu_options = [ {
    form_type: "Client",
    form_list: [{ value: '', label: 'Select', orgType: [''] },
    { value: '1', label: 'Client', orgType: ['1', '3', '4'] },
    { value: '2', label: 'Referral', orgType: ['1', '2', '3','4'] },
    { value: '3', label: 'Youth', orgType: ['2', '3'] },
    { value: '4', label: 'Juvenile', orgType: ['2'] },
    { value: '5', label: 'Child', orgType: ['2', '3'] },
    {value: '6', label: 'Probation', orgType: ['1', '2', '4'] },
    {value: '7', label: 'DJS', orgType: ['1', '2', '4'] },
    {value: '8', label: 'Diversion', orgType: ['1', '2', '4'] },
    {value: '9', label: 'Community Based', orgType: ['1', '2', '4'] },
    {value: '10', label: 'Diversionary Program', orgType: ['1', '2', '4'] },
    {value: '11', label: 'Supplemental Intervention', orgType: ['1', '2', '4'] },
    {value: '12', label: 'Residential Placement', orgType: ['1', '2', '4'] },
    {value: '13', label: 'Out of Home Placement', orgType: ['1', '2', '4'] },
    {value: '14', label: 'Detention', orgType: ['1', '2', '4'] },
     ]},
     {
        form_type: "Family",
        form_list: [
            { value: '', label: 'Select', orgType: [''] },
            { value: '1', label: 'Home', orgType: ['3'] },
            { value: '2', label: 'Family', orgType: ['3'] }
        ]},
];

class AddOrganization extends React.Component {
    constructor() {
        super();
        this.state = {
            name: '',
            country: 'US',
            org_name: '',
            mobile: '',
            email_id: '',
            error: '',
            org_type: null,
            referral_info: {},
            titleCount: []
        };
    }
    async componentDidMount() {
        const { id } = this.props.match.params;
        const currentUser = store.getState().loginData.user.token;
        if (id) {
            try {
                const response = await axios
                    .get(`${baseApiUrl}/customers/${id}/`, {
                        headers: {
                            Authorization: `Bearer ${currentUser}`
                        }
                    })
                    .then((response) => {
                        return response.data;
                    });
                this.setState({
                    name: response.name,
                    org_name: response.org_name,
                    email_id: response.email_id,
                    mobile: response.mobile,
                    org_type: response.org_type,
                    referral_info: response.referral_info
                });
            } catch (error) {
                console.log(error, 'error');
            }
        }
    }
    addORG = async (e) => {
        e.preventDefault();
        const { id } = this.props.match.params;
        const data = this.state;
        
        try {
            const response = await AddOrganizations(data, id);
            if (response.status === 'failed') {
                const err = response.response;
                const msg = Object.keys(err).map((m) => err[m]);
                this.setState({
                    error: msg
                });
            } else {
                toast.info(`User ${id ? 'updated' : 'added'}  successfully.`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 3000
                });
                this.props.history.push('/admin/configure/organizationlist');
            }
        } catch (e) {
            this.setState({
                error: e.message
            });
            return;
        }
    };

    handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'email_id') {
            this.setState({
                [name]: value
            });
        } else if (name === 'org_type') {
                let forms = org_options.find((org) => org.value === value)?.forms;
                let referralInfo = [];
                forms.map((list) => menu_options.map((menu, index) => {
                    if(menu.form_type === list.name) {
                        referralInfo = [...referralInfo, {config_type: list.type,config_name: list.name,[`referral_forms`]: [{sub_config_type: 1, label: ""}]}]
                    }
                }))
                
            this.setState({
                [name]: value && parseInt(value),
                referral_info: referralInfo
            });
        } else {
           this.setState({
                [name]: value.charAt(0).toUpperCase() + value.substr(1)
            });
        }
    };
    onRadioChange = (e) => {
        this.setState({
            gender: e.currentTarget.value
        });
    };

    onAddTitle = (data, ref_data) => {
        this.setState((prevState) => {
            return {
                referral_info: prevState.referral_info.map((item) => {
                    if (item.config_name === ref_data.config_name) {
                        return {
                            ...item,
                            // Add a new form with incremented sub_config_type
                            referral_forms: [
                                ...item.referral_forms,
                                {
                                    sub_config_type: item.referral_forms.length + 1,
                                    label: ""
                                }
                            ]
                        };
                    }
                    // If config_name doesn't match, return the original item
                    return item;
                })
            };
        });
    };

    onRemoveTitle = (ref_data, sub_config_type_to_remove) => {
        this.setState((prevState) => {
            return {
                referral_info: prevState.referral_info.map((item) => {
                    if (item.config_name === ref_data.config_name) {
                        return {
                            ...item,
                            // Remove the item with the matching sub_config_type
                            referral_forms: item.referral_forms.filter(
                                (form) => form.sub_config_type !== sub_config_type_to_remove
                            )
                        };
                    }
                    // If config_name doesn't match, return the original item
                    return item;
                })
            };
        });
    };
    
    onTitlehandleChange = (e, data, index) => {
        data.referral_forms[index].label = e.target.value;
        let value = e.target.value;
        this.setState((prevState) => {
            return {
                referral_info: prevState.referral_info.map((item) => {
                    if (item.config_name === data.config_name) {
                        return {
                            ...item,
                            // Update label value with the matching sub_config_type
                            referral_forms: item.referral_forms.map(
                                (form) => {
                                    if(form.sub_config_type === data.referral_forms[index].sub_config_type){
                                        return {
                                            ...form,
                                            label: value
                                        }
                                    }
                                     return form;
                                }
                            )
                        };
                    }
                    // If config_name doesn't match, return the original item
                    return item;
                })
            };
        });
        
    }

     getFilteredOptions = (currentDropdownId, menuList, selectedValues) => {
        const selectedSet = new Set(
            selectedValues.filter((_, idx) => idx !== currentDropdownId && selectedValues[idx])
          );
          let options =
           menuList.filter(
            (option) => (!selectedSet.has(option.label) || selectedValues[currentDropdownId] === option.label) &&(option.orgType.includes(this.state.org_type?.toString()) )
          );
          
          return options
      };

      finalFilteredOptions = (formType) => {
        let finalOptions = menu_options
          .filter(menu_list => menu_list.form_type === formType)
          .map(menu_list => {
            return menu_list.form_list.filter(item => 
              item.orgType.includes(this.state.org_type?.toString())
            );
          })
          .flat(); // Flatten the array if `form_list` is nested arrays
      
        return finalOptions;
      }
    
    render() {
        const { id } = this.props.match.params;
        const {referral_info} = this.state;
        toast.configure();
       
        return (
            <div className="container-fluid">
                <form className="" onSubmit={this.addORG}>
                    <div className="row p-2 bg-primary text-white">Organization</div>
                    <br />

                    <div className=" ml-4">
                        {this.state.error && <div className="col -4 text-center text-danger mb-3 font-weight-bold">{this.state.error}</div>}
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label font-weight-bold ">Name:</label>
                            <div className="col-sm-4">
                                <input type="text" onChange={this.handleChange} value={this.state.name} name="name" className="form-control " required />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label font-weight-bold ">Organization Name:</label>
                            <div className="col-sm-4">
                                <input type="text" onChange={this.handleChange} value={this.state.org_name} name="org_name" className="form-control " required />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label font-weight-bold ">Mobile Number:</label>
                            <div className="col-sm-4">
                                <input type="number" onChange={this.handleChange} value={this.state.mobile} name="mobile" className="form-control " required />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label font-weight-bold ">Email ID:</label>
                            <div className="col-sm-4">
                                <input type="email" onChange={this.handleChange} value={this.state.email_id} name="email_id" className="form-control " required />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label font-weight-bold">Organization Type:</label>
                            <div className="col-sm-4">
                                <div class="dropdown">
                                    <select className="form-control" id="org_type" name="org_type" onChange={this.handleChange} value={this.state.org_type} required disabled={id}>
                                        {org_options.map((org, i) => (
                                            <option key={i} value={org.value}>
                                                {org.label}
                                            </option>
                                        ))}
                                    </select>
                                    {this.state.org_type ? <span className={`small ${id ? 'text-danger' : 'text-primary'}`}>{`You can not ${id ? 'change' : 're-change'} organization type, after successful creation of organization.`}</span> : ''}
                                </div>
                            </div>
                        </div>
                        {referral_info.length > 0 && 
                        referral_info.map((ref_list, ref_index) => {
                            let selectedTitles = ref_list.referral_forms.map((item) => item.label);
                            
                            return (
                            <React.Fragment>
                                <div className="form-group row">
                             <label className="col-form-label font-weight-bold">Add and Select {ref_list.config_name} Forms Title below</label>
                             </div>
                                {ref_list.referral_forms.map((form_list, form_index) => (
                                    <React.Fragment>
                                          
                             <div className="form-group row">
                                 <label className="col-sm-2 col-form-label">Title {form_index+1}:</label>
                                 <div className="col-sm-4">
                                     <div class="dropdown">
                                         <select className="form-control" id="referral_1" name="referral_1" 
                                         onChange={(e) => this.onTitlehandleChange(e,ref_list,form_index)} 
                                         value={form_list.label}
                                         required >
                                             <option key='' value=''>
                                                 Select 
                                             </option>
                                             {menu_options.map((menu_list, opt_i) => menu_list.form_type === ref_list.config_name && 
                                                 this.getFilteredOptions(form_index,menu_list.form_list,selectedTitles).map((org, i) => 
                                                    org.orgType.includes(this.state.org_type?.toString()) &&  (
                                                        <option key={i} value={org.label}>
                                                            {org.label}
                                                        </option>
                                                    )
                                            ))
                                            }
                                            </select>
                                     </div>
                                 </div>
                                 {/* {form_index !== 0 && (
                                 <div
                                     style={{
                                         width: '25px',
                                         height: '25px',
                                         fontSize: '24px',
                                         color: 'red'
                                     }}
                                     className="font-text-bold text-center "
                                      onClick={() => this.onRemoveTitle(ref_list, form_list.sub_config_type)}
                                 >
                                     <i className="fa fa-remove"></i>
                                 </div>
                              )}  */} 
                           
                           {ref_list.referral_forms.length === form_index + 1 && ref_list.referral_forms.length !== this.finalFilteredOptions(ref_list.config_name).length &&
                             <div
                                 style={{
                                     width: '25px',
                                     height: '25px',
                                     fontSize: '24px'
                                 }}
                                 className="font-text-bold text-center "
                                 onClick={() => this.onAddTitle(form_list, ref_list)}
                             >
                                 <i
                                     className="fa fa-plus"
                                     style={{
                                         fontSize: '28px'
                                     }}
                                 ></i>
                             </div>
    }
                             </div>
                                    </React.Fragment>
                                ))}
                                
                               
                                </React.Fragment>
                        )}
                       )
                        }
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label font-weight-bold "></label>
                            <div className="col-sm-4">
                                <button type="submit" className="btn btn-primary font-weight-bold btn-block">
                                    {id ? 'UPDATE' : 'ADD ORGANIZATION'}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="row p-2 bd-highlight">
                    <div className="col col-3">
                        <Link to={`/admin/configure/organization/adduser/id=${id}`}>Add Admin Users</Link>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.loginData.user
    };
};

export default connect(mapStateToProps)(AddOrganization);
